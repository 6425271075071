<template>
  <div>
    <div
      class="layout-header-section d-lg-flex d-block justify-content-between"
    >
      <div class="header-label col-lg-6 col-12">Branches List</div>
      <div
        class="col-lg-6 col-12 text-end d-flex justify-content-end custom-flex-cloumn-mob"
      >
        <button
          type="button"
          class="header-btn-outer btn btn-primary"
          @click="addBranchModalOpen()"
        >
          Add Branch
        </button>
      </div>
    </div>
    <div class="custom-ultima-datatable" style="height: calc(100vh - 141px)">
      <DataTable
        :value="branchList"
        :scrollable="true"
        columnResizeMode="fit"
        scrollHeight="flex"
        :paginator="true"
        :rows="30"
        :lazy="true"
        :totalRecords="totalRecords"
        @page="changePage($event)"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        responsiveLayout="scroll"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        :loading="loading"
        dataKey="ae1"
        class="p-datatable-sm"
      >
        <template v-if="!loading" #empty>No records found.</template>
        <template #loading
          ><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i
        ></template>
        <Column
          field="branchName"
          header="Branch Name"
          headerStyle="width: 30%"
          bodyStyle="width: 30%"
        >
          <template #body="{ data }">
            <div>
              <div class="text-capitalize font-semibold-header">
                {{ data.ae5 ? data.ae5 : "N/A" }}
              </div>
              <div class="text-capitalize">{{ data.ae14 }}</div>
            </div>
          </template>
        </Column>
        <Column
          field="Address"
          header="Address"
          headerStyle="width: 45%"
          bodyStyle="width: 45%"
        >
          <template #body="{ data }">
            <div>
              <div class="text-capitalize">
                <span
                  class="on-hover-cursor"
                  data-toggle="tooltip"
                  title="State"
                  data-placement="top"
                  >{{ data.ae17 ? data.ae17 : "N/A" }}</span
                >
                &nbsp;|&nbsp;
                <span
                  class="on-hover-cursor"
                  data-toggle="tooltip"
                  title="City"
                  data-placement="top"
                  >{{ data.ae7 ? data.ae7 : "N/A" }}</span
                >&nbsp;|&nbsp;
                <span
                  class="on-hover-cursor"
                  data-toggle="tooltip"
                  title="Area"
                  data-placement="top"
                  >{{ data.ae9 ? data.ae9 : "N/A" }}</span
                >
              </div>
              <Divider />
              <div>
                {{ data.ae11 }},
                <span>{{ data.ae10 }}</span>
              </div>
              <Divider />
              <div>
                {{ data.ae12 }}
              </div>
            </div>
          </template>
        </Column>
        <Column
          field="status"
          header="Status"
          class="justify-content-center"
          headerStyle="width: 10%"
          bodyStyle="width: 10%"
        >
          <template #body="{ data }">
            <div>
              <InputSwitch
                v-model="data.ae18"
                :true-value="dynamicTrueActiveValue"
                :false-value="dynamicFalseInactiveValue"
                @change="confirmModalOpen(data)"
              />
            </div>
          </template>
        </Column>
        <Column
          field="action"
          header="Action"
          class="justify-content-center"
          headerStyle="width: 15%"
          bodyStyle="width: 15%"
        >
          <template #body="{ data }">
            <button
              type="button"
              class="btn custom-view-detail-btn"
              @click="editBranchModalOpen(data)"
            >
              Edit Details
            </button>
          </template>
        </Column>
      </DataTable>
    </div>
    <!-- Add Custom Designation Start Here -->
    <div class="modal-mask" v-if="addbranchDialog">
      <div
        class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered custom-modal-outer"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">Add Branch</h5>
            <button
              type="button"
              class="btn-close"
              @click="addBranchModalClose()"
            ></button>
          </div>
          <div class="modal-body modal-body-scroll-outer">
            <div class="row">
              <div class="col-lg-4 col-md-4 col-12">
                <div class="custom-form-group">
                  <label class="form-label"
                    >Branch Name<span class="text-danger">*</span></label
                  >
                  <input
                    type="text"
                    :searchable="true"
                    :disabled="btnloader"
                    label="label"
                    v-model="add.branchname"
                    class="form-control text-capitalize"
                    id="formLastNameInput"
                    placeholder="Enter Branch Name"
                    autocomplete="off"
                    maxlength="50"
                    required
                  />
                  <div class="custom-error" v-if="v$.add.branchname.$error">
                    {{ v$.add.branchname.$errors[0].$message }}
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-12">
                <div class="custom-form-group">
                  <label class="form-label"
                    >Select Category<span class="text-danger">*</span></label
                  >
                  <Multiselect
                    v-model="add.category"
                    :disabled="btnloader"
                    :options="categoryList"
                    :searchable="true"
                    label="label"
                    placeholder="Select Category"
                    class="multiselect-custom"
                    :canClear="true"
                    :closeOnSelect="true"
                    :object="true"
                    noOptionsText="No Result"
                  />
                  <div class="custom-error" v-if="v$.add.category.$error">
                    {{ v$.add.category.$errors[0].$message }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4 col-md-4 col-12">
                <div class="custom-form-group">
                  <label class="form-label"
                    >Select State<span class="text-danger">*</span></label
                  >
                  <Multiselect
                    v-model="add.state"
                    :disabled="btnloader"
                    :options="stateList"
                    :searchable="true"
                    label="label"
                    placeholder="Select"
                    class="multiselect-custom"
                    :canClear="false"
                    :closeOnSelect="true"
                    :object="true"
                    noOptionsText="No Result"
                    @keypress="isLetterWithSpace($event)"
                    @select="getCityByStateId(add.state)"
                    required
                  />
                  <div class="custom-error" v-if="v$.add.state.$error">
                    {{ v$.add.state.$errors[0].$message }}
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-12">
                <div class="custom-form-group">
                  <label class="form-label"
                    >Select City<span class="text-danger">*</span></label
                  >
                  <Multiselect
                    v-show="!cityloaderflag"
                    :disabled="btnloader"
                    v-model="add.city"
                    :options="cityList"
                    :searchable="true"
                    label="label"
                    placeholder="Select"
                    class="multiselect-custom"
                    @keypress="isLetterWithSpace($event)"
                    :canClear="true"
                    :closeOnSelect="true"
                    noOptionsText="No Result"
                    :object="true"
                    @select="getAreaByCityId(add.city)"
                    required
                  />
                  <Multiselect
                    v-show="cityloaderflag"
                    loading
                    :searchable="false"
                    label="label"
                    placeholder="Select"
                    class="multiselect-custom"
                    noOptionsText="No Result"
                  />
                  <div class="custom-error" v-if="v$.add.city.$error">
                    {{ v$.add.city.$errors[0].$message }}
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-12">
                <div class="custom-form-group">
                  <label class="form-label"
                    >Select Area<span class="text-danger">*</span></label
                  >
                  <Multiselect
                    v-show="!arealoaderflag"
                    :disabled="btnloader"
                    v-model="add.area"
                    :options="areaList"
                    :searchable="true"
                    label="label"
                    placeholder="Select"
                    class="multiselect-custom"
                    @keypress="isLetterWithSpace($event)"
                    :canClear="true"
                    :closeOnSelect="true"
                    @select="getPincodeAreaId(add.area)"
                    noOptionsText="No Result"
                    :object="true"
                    required
                  />
                  <Multiselect
                    v-show="arealoaderflag"
                    loading
                    :searchable="false"
                    label="label"
                    placeholder="Select"
                    class="multiselect-custom"
                    noOptionsText="No Result"
                  />
                  <div class="custom-error" v-if="v$.add.area.$error">
                    {{ v$.add.area.$errors[0].$message }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-8 col-md-8 col-12">
                <div class="custom-form-group">
                  <label for="formShortNameInput" class="form-label"
                    >Address<span class="text-danger">*</span></label
                  >
                  <textarea
                    type="text"
                    v-model="add.fulladdress"
                    :disabled="btnloader"
                    class="form-control custom-text-area-scroll"
                    placeholder="Enter Full Address"
                    autocomplete="off"
                    rows="2"
                    maxlength="250"
                  ></textarea>
                  <div class="custom-error" v-if="v$.add.fulladdress.$error">
                    {{ v$.add.fulladdress.$errors[0].$message }}
                  </div>
                  <!-- <div>
      <vue-google-autocomplete
        id="map"
        placeholder="Start typing"
        @placechanged="onPlaceChanged"
        v-model="address"
        :options="options"
      ></vue-google-autocomplete>
    </div> -->
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-12">
                <div class="custom-form-group">
                  <label class="form-label"
                    >Pin Code<span class="text-danger">*</span></label
                  >
                  <input
                    type="text"
                    v-model="add.pincode"
                    :disabled="btnloader"
                    class="form-control text-capitalize"
                    placeholder="Enter Pin Code"
                    maxlength="6"
                    @keypress="onlyNumber"
                    autocomplete="off"
                  />
                  <div class="custom-error" v-if="v$.add.pincode.$error">
                    {{ v$.add.pincode.$errors[0].$message }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 col-md-12 col-12">
                <div class="custom-form-group">
                  <label class="form-label"
                    >Google Map Link<span class="text-danger">*</span></label
                  >
                  <input
                    type="text"
                    v-model="add.maplink"
                    :disabled="btnloader"
                    class="form-control"
                    placeholder="Enter Map Link"
                    autocomplete="off"
                  />
                  <div class="custom-error" v-if="v$.add.maplink.$error">
                    {{ v$.add.maplink.$errors[0].$message }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="col-lg-12 col-md-12 col-12 text-end">
              <button
                type="button"
                class="btn user-role-btn"
                @click="addbranchbtn()"
                :disabled="btnloader"
              >
                <span v-if="!btnloader">Submit</span>
                <div
                  class="spinner-border text-light custom-spinner-loader-btn"
                  role="status"
                  v-if="btnloader"
                >
                  <span class="visually-hidden">Loading...</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Add Custom Designation End Here -->
    <!-- confirmation modal start here -->
    <div class="modal-mask" v-if="confirm_popup_status">
      <div
        class="modal-dialog modal-xs modal-dialog-centered custom-modal-outer confirm-dialog-modal"
      >
        <div class="modal-content text-center">
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <div class="confirm-dialog-header">Confirmation</div>
              </div>
              <div class="col-12">
                <div class="d-flex align-items-center">
                  <div class="flex-shrink-0">
                    <i
                      class="pi pi-exclamation-triangle"
                      style="font-size: 2rem"
                    ></i>
                  </div>
                  <div class="flex-grow-1 ms-3 confirm-dialog-body">
                    Are you sure you want to proceed?
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="col-12 text-end">
              <button
                type="button"
                :disabled="showconfirmloaderbtn"
                class="btn confirm-modal-no-btn"
                @click="confirmModalClose()"
              >
                No
              </button>
              <button
                type="button"
                class="btn confirm-modal-yes-btn"
                @click="changeHostBranchStatus()"
                :disabled="showconfirmloaderbtn"
              >
                <span v-if="!showconfirmloaderbtn">Yes</span>

                <div
                  class="spinner-border text-light custom-spinner-loader-btn"
                  role="status"
                  v-if="showconfirmloaderbtn"
                ></div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- confirmation modal end here -->
    <!-- Start For Edit Branch Details Open Model  -->
    <div class="modal-mask" v-if="isEditBranchDetails">
      <div
        class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered custom-modal-outer"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">Edit Branch</h5>
            <button
              type="button"
              class="btn-close"
              @click="editBranchModalClose()"
            ></button>
          </div>
          <div class="modal-body modal-body-scroll-outer">
            <div class="row">
              <div class="col-lg-4 col-md-4 col-12">
                <div class="custom-form-group">
                  <label class="form-label"
                    >Branch Name<span class="text-danger">*</span></label
                  >
                  <input
                    type="text"
                    :searchable="true"
                    :disabled="submitEditDataLoader"
                    label="label"
                    v-model="edit.branchname"
                    class="form-control text-capitalize"
                    id="formLastNameInput"
                    placeholder="Enter Branch Name"
                    autocomplete="off"
                    maxlength="50"
                    required
                  />
                  <div class="custom-error" v-if="v$.edit.branchname.$error">
                    {{ v$.edit.branchname.$errors[0].$message }}
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-12">
                <div class="custom-form-group">
                  <label class="form-label"
                    >Select Category<span class="text-danger">*</span></label
                  >
                  <Multiselect
                    v-model="edit.category"
                    :disabled="submitEditDataLoader"
                    :options="categoryList"
                    :searchable="true"
                    label="label"
                    placeholder="Select Category"
                    class="multiselect-custom"
                    :canClear="true"
                    :closeOnSelect="true"
                    :object="true"
                    noOptionsText="No Result"
                  />
                  <div class="custom-error" v-if="v$.edit.category.$error">
                    {{ v$.edit.category.$errors[0].$message }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4 col-md-4 col-12">
                <div class="custom-form-group">
                  <label class="form-label"
                    >Select State<span class="text-danger">*</span></label
                  >
                  <Multiselect
                    v-model="edit.state"
                    :options="stateList"
                    :disabled="submitEditDataLoader"
                    :searchable="true"
                    label="label"
                    placeholder="Select"
                    class="multiselect-custom"
                    :canClear="false"
                    :closeOnSelect="true"
                    :object="true"
                    noOptionsText="No Result"
                    @keypress="isLetterWithSpace($event)"
                    @select="getCityByStateId(edit.state)"
                    required
                  />
                  <div class="custom-error" v-if="v$.edit.state.$error">
                    {{ v$.add.edit.$errors[0].$message }}
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-12">
                <div class="custom-form-group">
                  <label class="form-label"
                    >Select City<span class="text-danger">*</span></label
                  >
                  <Multiselect
                    v-show="!cityloaderflag"
                    v-model="edit.city"
                    :disabled="submitEditDataLoader"
                    :options="cityList"
                    :searchable="true"
                    label="label"
                    placeholder="Select"
                    class="multiselect-custom"
                    @keypress="isLetterWithSpace($event)"
                    :canClear="true"
                    :closeOnSelect="true"
                    noOptionsText="No Result"
                    :object="true"
                    @select="getAreaByCityId(edit.city)"
                    required
                  />
                  <Multiselect
                    v-show="cityloaderflag"
                    loading
                    :searchable="false"
                    label="label"
                    placeholder="Select"
                    class="multiselect-custom"
                    noOptionsText="No Result"
                  />
                  <div class="custom-error" v-if="v$.edit.city.$error">
                    {{ v$.edit.city.$errors[0].$message }}
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-12">
                <div class="custom-form-group">
                  <label class="form-label"
                    >Select Area<span class="text-danger">*</span></label
                  >
                  <Multiselect
                    v-show="!arealoaderflag"
                    v-model="edit.area"
                    :disabled="submitEditDataLoader"
                    :options="areaList"
                    :searchable="true"
                    label="label"
                    placeholder="Select"
                    class="multiselect-custom"
                    @select="getPincodeAreaId(edit.area)"
                    @keypress="isLetterWithSpace($event)"
                    :canClear="true"
                    :closeOnSelect="true"
                    noOptionsText="No Result"
                    :object="true"
                    required
                  />
                  <Multiselect
                    v-show="arealoaderflag"
                    loading
                    :searchable="false"
                    label="label"
                    placeholder="Select"
                    class="multiselect-custom"
                    noOptionsText="No Result"
                  />
                  <div class="custom-error" v-if="v$.edit.area.$error">
                    {{ v$.edit.area.$errors[0].$message }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-8 col-md-8 col-12">
                <div class="custom-form-group">
                  <label for="formShortNameInput" class="form-label"
                    >Address<span class="text-danger">*</span></label
                  >
                  <textarea
                    type="text"
                    v-model="edit.fulladdress"
                    :disabled="submitEditDataLoader"
                    class="form-control custom-text-area-scroll"
                    placeholder="Enter Full Address"
                    autocomplete="off"
                    rows="2"
                    maxlength="250"
                  ></textarea>
                  <div class="custom-error" v-if="v$.add.fulladdress.$error">
                    {{ v$.edit.fulladdress.$errors[0].$message }}
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-12">
                <div class="custom-form-group">
                  <label class="form-label"
                    >Pin Code<span class="text-danger">*</span></label
                  >
                  <input
                    type="text"
                    v-model="edit.pincode"
                    :disabled="submitEditDataLoader"
                    class="form-control text-capitalize"
                    placeholder="Enter Pin Code"
                    maxlength="6"
                    @keypress="onlyNumber"
                    autocomplete="off"
                  />
                  <div class="custom-error" v-if="v$.edit.pincode.$error">
                    {{ v$.edit.pincode.$errors[0].$message }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 col-md-12 col-12">
                <div class="custom-form-group">
                  <label class="form-label"
                    >Google Map Link<span class="text-danger">*</span></label
                  >
                  <input
                    type="text"
                    v-model="edit.maplink"
                    :disabled="submitEditDataLoader"
                    class="form-control"
                    placeholder="Enter Map Link"
                    autocomplete="off"
                  />
                  <div class="custom-error" v-if="v$.edit.maplink.$error">
                    {{ v$.edit.maplink.$errors[0].$message }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="col-lg-12 col-md-12 col-12 text-end">
              <button
                type="button"
                class="btn user-role-btn"
                @click="submitUpdatedBranchDetails()"
                :disabled="submitEditDataLoader"
              >
                <span v-if="!submitEditDataLoader">Submit</span>
                <div
                  class="spinner-border text-light custom-spinner-loader-btn"
                  role="status"
                  v-if="submitEditDataLoader"
                >
                  <span class="visually-hidden">Loading...</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End For Edit Branch Details Open Model  -->
  </div>
</template>
<script>
import ApiService from "../../service/ApiService";
import { required, minLength, url, helpers } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
// import GoogleMapsLoader from "google-maps";
// import VueGoogleAutocomplete from "vue-google-autocomplete";

export default {
//   components: {
//     VueGoogleAutocomplete,
//   },
  data() {
    return {
      v$: useValidate(),
      btnloader: false,
      branchList: null,
      totalRecords: 0,
      loading: false,
      page_no: 0,
      modalbtnloader: false,
      addbranchDialog: false,
      roleid: "",
      add: {
        branchname: "",
        category: "",
        state: { value: 11, label: "Goa" },
        city: "",
        area: "",
        fulladdress: "",
        pincode: "",
        maplink: "",
      },
      edit: {
        branchname: "",
        category: "",
        state: "",
        city: "",
        area: "",
        fulladdress: "",
        pincode: "",
        maplink: "",
      },
      categoryList: [],
      stateList: [],
      cityList: [],
      areaList: [],
      dynamicTrueActiveValue: 1,
      dynamicFalseInactiveValue: 0,
      confirm_popup_status: false,
      showconfirmloaderbtn: false,
      confirm_designation_id: "",
      confirm_status_value: "",
      arealoaderflag: false,
      hostId: "",
      isEditBranchDetails: false,
      editedBranchID: "",
      submitEditDataLoader: false,
      address: "",
      options: {
        types: ["(cities)"],
        componentRestrictions: { country: "us" }, // Optionally restrict to a specific country
      },
    };
  },
  validations() {
    return {
      add: {
        branchname: {
          required: helpers.withMessage("Please select branch name", required),
        },
        category: {
          required: helpers.withMessage("Please select category", required),
        },
        state: {
          required: helpers.withMessage("Please select state", required),
        },
        city: { required: helpers.withMessage("Please select city", required) },
        area: { required: helpers.withMessage("Please select area", required) },
        fulladdress: {
          required: helpers.withMessage("Please enter full address", required),
        },
        pincode: {
          required: helpers.withMessage("Please enter pincode", required),
          minLength: helpers.withMessage(
            "Pincode should be min 6 length",
            minLength(6)
          ),
        },
        maplink: {
          required: helpers.withMessage("Please enter map link", required),
          url: helpers.withMessage("Enter vaild map link", url),
        },
      },
      edit: {
        branchname: {
          required: helpers.withMessage("Please select branch name", required),
        },
        category: {
          required: helpers.withMessage("Please select category", required),
        },
        state: {
          required: helpers.withMessage("Please select state", required),
        },
        city: { required: helpers.withMessage("Please select city", required) },
        area: { required: helpers.withMessage("Please select area", required) },
        fulladdress: {
          required: helpers.withMessage("Please enter full address", required),
        },
        pincode: {
          required: helpers.withMessage("Please enter pincode", required),
          minLength: helpers.withMessage(
            "Pincode should be min 6 length",
            minLength(6)
          ),
        },
        maplink: {
          required: helpers.withMessage("Please enter map link", required),
          url: helpers.withMessage("Enter vaild map link", url),
        },
      },
    };
  },
  ApiService: null,
  created() {
    this.ApiService = new ApiService();
  },
  mounted() {
    this.showmodalloader = true;
    var user_info = JSON.parse(localStorage.user);
    this.hostId = user_info.ad2;
    this.getHostBranchesByHostId({ ac1: this.hostId });
    this.getstates();
    this.getLoadCityApi();
    this.getCategories();
  },
  methods: {
    getPincodeAreaId(e) {
      this.add.pincode = e.pincode;
      this.edit.pincode = e.pincode;
    },
    getCategories(e) {
      this.ApiService.getCategories(e).then((data) => {
        if (data.status == 200) {
          this.categoryList = data.data;
        } else {
          this.categoryList = "";
        }
      });
    },
    getstates() {
      this.ApiService.getstates().then((data) => {
        if (data.success == true) {
          this.stateList = data.data;
          this.showloadervotermodal = false;
        }
      });
    },
    getLoadCityApi() {
      this.cityloaderflag = true;
      this.ApiService.getcities({ state_id: this.add.state.value }).then(
        (data) => {
          if (data.success == true) {
            this.cityList = data.data;
            this.cityloaderflag = false;
          } else {
            this.cityloaderflag = false;
            this.cityList = null;
          }
        }
      );
    },
    getCityByStateId(e) {
      this.cityloaderflag = true;
      this.add.city = "";
      this.add.area = "";
      this.edit.city = "";
      this.edit.area = "";
      this.ApiService.getcities({ state_id: e.value }).then((data) => {
        if (data.success == true) {
          this.cityList = data.data;
          this.cityloaderflag = false;
        } else {
          this.cityloaderflag = false;
          this.cityList = [];
        }
      });
    },
    getAreaByCityId(e) {
      this.arealoaderflag = true;
      this.add.area = "";
      this.ApiService.getareas({ city_id: e.value }).then((data) => {
        if (data.success == true) {
          this.areaList = data.data;
          this.arealoaderflag = false;
        } else {
          this.arealoaderflag = false;
          this.areaList = [];
        }
      });
    },
    getHostBranchesByHostId(e) {
      // console.log(e);
      this.loading = true;
      this.ApiService.getHostBranchesByHostId(e).then((data) => {
        if (data.success == true) {
          this.branchList = data.data;
          this.totalRecords = data.count;
          this.loading = false;
        } else {
          this.branchList = null;
          this.totalRecords = 0;
          this.loading = false;
        }
      });
    },
    addbranchbtn() {
      this.v$.add.$validate();
      let fields = {};
      // console.log(this.hostId);
      fields["ae2"] = this.hostId;
      fields["ae4"] = 2;
      fields["ae5"] = this.add.branchname;
      fields["ae13"] = this.add.category.value;
      fields["ae14"] = this.add.category.label;
      fields["ae16"] = this.add.state.value;
      fields["ae17"] = this.add.state.label;
      fields["ae6"] = this.add.city.value;
      fields["ae7"] = this.add.city.label;
      fields["ae8"] = this.add.area.value;
      fields["ae9"] = this.add.area.label;
      fields["ae11"] = this.add.fulladdress;
      fields["ae10"] = this.add.pincode;
      fields["ae12"] = this.add.maplink;
      if (!this.v$.add.$error) {
        this.btnloader = true;
        this.ApiService.addOrUpdateHostBranch(fields).then((items) => {
          if (items.success == true) {
            this.$toast.open({
              message: items.message,
              type: "success",
              duration: 3000,
              position: "top-right",
            });
            setTimeout(() => {
              this.v$.$reset();
            }, 0);
            this.btnloader = false;
            this.addbranchDialog = false;
            this.getHostBranchesByHostId({ ac1: this.hostId });
          } else {
            this.$toast.open({
              message: items.message,
              type: "error",
              duration: 3000,
              position: "top-right",
            });
            this.btnloader = false;
          }
        });
      }
    },
    addBranchModalOpen() {
      this.addbranchDialog = true;
      this.add.branchname = "";
      this.add.category = "";
      this.add.city = "";
      this.add.area = "";
      this.add.fulladdress = "";
      this.add.pincode = "";
      this.add.maplink = "";
    },
    addBranchModalClose() {
      this.addbranchDialog = false;
      setTimeout(() => {
        this.v$.$reset();
      }, 0);
    },
    editBranchModalClose() {
      this.isEditBranchDetails = false;
      setTimeout(() => {
        this.v$.$reset();
      }, 0);
    },
    isLetterWithSpace(e) {
      let charspace = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z\s]+$/.test(charspace)) return true;
      else e.preventDefault();
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    },
    changePage(event) {
      this.page_no = event.page;
      this.getHostBranchesByHostId({ page_no: this.page_no });
    },
    confirmModalOpen(e) {
      this.confirm_designation_id = e.ae1;
      this.confirm_status_value = e.ae18;
      this.confirm_popup_status = true;
    },

    confirmModalClose() {
      this.confirm_popup_status = false;
      this.confirm_designation_id = "";
      this.confirm_status_value = "";
    },
    changeHostBranchStatus() {
      let fields = {};
      fields["ae1"] = this.confirm_designation_id;
      if (
        this.confirm_status_value == 0 ||
        this.confirm_status_value == false
      ) {
        fields["status"] = 0;
      }
      if (this.confirm_status_value == 1 || this.confirm_status_value == true) {
        fields["status"] = 1;
      }
      this.showconfirmloaderbtn = true;
      // console.log(fields);
      this.ApiService.changeHostBranchStatus(fields).then((items) => {
        if (items.status === 200) {
          var successMsg = items.message;
          this.$toast.open({
            message: successMsg,
            type: "success",
            duration: 3000,
            position: "top-right",
          });
          this.showconfirmloaderbtn = false;
          this.getHostBranchesByHostId({ac1: this.hostId});
          this.confirm_designation_id = "";
          this.confirm_status_value = "";
          this.confirm_popup_status = false;
        } else {
          this.showconfirmloaderbtn = false;
          var errorMsg = items.message;
          this.$toast.open({
            message: errorMsg,
            type: "error",
            duration: 3000,
            position: "top-right",
          });
        }
        this.confirm_popup_status = false;
      });
    },
    editBranchModalOpen(data) {
      this.editedBranchID = data.ae1;
      // console.log(this.editedBranchID);
      this.isEditBranchDetails = true;
      this.edit.branchname = data.ae5;
      this.edit.category = { value: data.ae13, label: data.ae14 };
      this.edit.state = { value: data.ae16, label: data.ae17 };
      this.edit.city = { value: data.ae6, label: data.ae7 };
      this.edit.area = { value: data.ae8, label: data.ae9 };
      this.edit.pincode = data.ae10;
      this.edit.maplink = data.ae12;
      this.edit.fulladdress = data.ae11;
    },
    submitUpdatedBranchDetails() {
      this.v$.edit.$validate();
      let fields = {};
      fields["ae1"] = this.editedBranchID;
      fields["ae2"] = this.hostId;
      fields["ae4"] = 2;
      fields["ae5"] = this.edit.branchname;
      fields["ae13"] = this.edit.category.value;
      fields["ae14"] = this.edit.category.label;
      fields["ae16"] = this.edit.state.value;
      fields["ae17"] = this.edit.state.label;
      fields["ae6"] = this.edit.city.value;
      fields["ae7"] = this.edit.city.label;
      fields["ae8"] = this.edit.area.value;
      fields["ae9"] = this.edit.area.label;
      fields["ae11"] = this.edit.fulladdress;
      fields["ae10"] = this.edit.pincode;
      fields["ae12"] = this.edit.maplink;
      if (!this.v$.edit.$error) {
        this.submitEditDataLoader = true;
        this.ApiService.addOrUpdateHostBranch(fields).then((items) => {
          if (items.success == true) {
            this.$toast.open({
              message: items.message,
              type: "success",
              duration: 3000,
              position: "top-right",
            });
            setTimeout(() => {
              this.v$.$reset();
            }, 0);
            this.submitEditDataLoader = false;
            this.isEditBranchDetails = false;
            this.getHostBranchesByHostId({ ac1: this.hostId });
          } else {
            this.$toast.open({
              message: items.message,
              type: "error",
              duration: 3000,
              position: "top-right",
            });
            this.submitEditDataLoader = false;
          }
        });
      }
    },
    // onPlaceChanged(place) {
    //   // Handle the place change event
    //   console.log("Place changed", place);
    // },
  },
};
</script>
<style scoped>
.form-box-outer {
  padding: 16px 16px;
  margin-bottom: 15px;
  border: 1px solid #e0e5ed;
  border-radius: 0px 0px 4px 4px;
}

.search-voter-section-outer {
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
  height: 512px;
}

.search-voter-section-outer::-webkit-scrollbar {
  display: block;
}

.search-voter-section-outer::-webkit-scrollbar {
  width: 0.3em;
  height: 0.3em;
}

.search-voter-section-outer::-webkit-scrollbar-thumb {
  background-color: rgb(113 115 119 / 49%);
  border-radius: 3px;
}

.btn.user-role-btn {
  background: #5266e5 0% 0% no-repeat padding-box;
  border-color: #5266e5;
  border-radius: 4px;
  letter-spacing: 0.26px;
  color: #ffffff;
  font-size: 13px;
  line-height: 20px;
  padding: 6px 35px;
  font-family: "AcuminPro-Regular";
  min-width: 80px;
}

.user-role-btn:focus {
  box-shadow: none;
}

.modal-body-fixed-height {
  min-height: 260px;
}

.on-hover-cursor {
  cursor: pointer;
}
</style>